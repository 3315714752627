import Vue from 'vue'
import VueRouter from 'vue-router'


//Campaign
import Campaign from '../views/Campaign/Campaign'


Vue.use(VueRouter)

const checkAuth = async function(to, from, next) {
	// console.log(!window.localStorage.getItem("user_token"));
	let token = await window.localStorage.getItem("agent_token")
	
	await window.localStorage.setItem("previousUrl", to.fullPath)
	
	if(!token || token == 'null') {	
	  	next('/sign-in')
	}else{
	  next();
	}
}

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
		beforeEnter: checkAuth,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/tables',
		name: 'Tables',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
		beforeEnter: checkAuth,
	},
	// {
	// 	path: '/campaigns',
	// 	name: 'Campaign',
	// 	layout: "dashboard",
	// 	component: Campaign,
	// 	beforeEnter: checkAuth,
	// },
	// {
	// 	path: '/my-classes',
	// 	name: 'My Classes',
	// 	layout: "dashboard",
	// 	component: MyClasses,
	// 	beforeEnter: checkAuth,
	// },
	// {
	// 	path: '/my-classes/view/:uuid',
	// 	name: 'View Class',
	// 	layout: "dashboard",
	// 	component: ViewMyClass,
	// 	beforeEnter: checkAuth,
	// },
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
		beforeEnter: checkAuth,
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/forgot-password',
		name: 'Forgot Password',
		component: () => import('../views/ForgotPassword.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		component: () => import('../views/Sign-Up.vue'),
	},
	{
		path: '/verify-account',
		name: 'Verify Account',
		component: () => import('../views/VerifyAccount.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
